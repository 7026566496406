import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['parent', 'child', 'form', 'button']

  connect() {
    if (!!document.querySelector('[data-select-all-target="parent"]')) {
      this.bindParentChanged()
      this.bindChildrenChanged()
    }
  }

  bindParentChanged() {
    this.parentTarget.addEventListener('change', () => {
      this.childTargets.forEach(el => el.checked = this.parentTarget.checked)
      this.enableElement()
    })
  }

  bindChildrenChanged() {
    this.childTargets.forEach(el => {
      el.addEventListener('change', (e) => {
        this.parentTarget.checked = this.allChecked()
        this.enableElement()
      })
    })
  }

  allChecked() {
    return !this.childTargets.filter(el => !el.checked).length
  }

  hasChecked() {
    return !!this.childTargets.filter(el => el.checked).length
  }

  enableElement() {
    if (this.hasFormTarget) {
      this.formEnable()
    } else if (this.hasButtonTarget) {
      this.buttonTarget.disabled = !this.hasChecked()
      this.buttonTarget.dataset.ids =
        this.childTargets.filter(el => el.checked).map(box => box.value).join(',')
    }
  }

  formEnable() {
    this.formTarget.querySelector('select').disabled = !this.hasChecked()
    this.formTarget.querySelector('[type="submit"]').disabled = !this.hasChecked()
    if (this.hasChecked()) {
      this.formTarget.classList.add('d-flex')
      this.formTarget.classList.remove('d-none')
    } else {
      this.formTarget.classList.remove('d-flex')
      this.formTarget.classList.add('d-none')
    }
  }
}
