import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.addValidator()
  }

  addValidator() {
    $(this.element).validate({
      errorElement: 'div',
      errorClass: 'invalid-feedback',
      rules: {
        'setting[name]': {
          required: true
        }
      }
    });
  }
}
