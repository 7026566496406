import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    holdingKeyCode: Number
  }

  connect() {
    this.shiftHolding = false
    this.lastTab = 0
    this.range = [0, 0]
    addEventListener('keydown', e => {
      if (e.keyCode === this.holdingKeyCodeValue) {
        this.shiftHolding = true
      }
    })
    addEventListener('keyup', e => {
      if (e.keyCode === this.holdingKeyCodeValue) {
        this.shiftHolding = false
      }
    })
  }

  onItemSelected(e) {
    if (!this.shiftHolding) {
      if (!!e.target.checked)
        this.lastTab = Number(e.target.dataset.tab)
    } else {
      let tab = Number(e.target.dataset.tab)
      console.log(tab)
      console.log(this.range)
      if (tab < this.range[1] && tab >= this.range[0]) {
        for (let i = this.range[1]; i >= tab; i--) {
          let elm = document.querySelector(`[data-tab="${i}"]`)
          if (!!elm)
            elm.checked = false
        }
        this.range[1] = tab
      } else {
        this.range[0] = Math.min(tab, this.lastTab)
        this.range[1] = Math.max(tab, this.lastTab)
        for (let i = this.range[0]; i <= this.range[1]; i++) {
          let elm = document.querySelector(`[data-tab="${i}"]`)
          if (!!elm)
            elm.checked = true
        }
      }
    }

    let callbackName = e.target.dataset.callback
    if (!!callbackName)
      eval(callbackName)()
  }

  disconnect() {
    removeEventListener('keydown')
  }
}
