import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.bindScrollingEvent()
  }

  bindScrollingEvent() {
    document.addEventListener('scroll', e => {
      if (window.pageYOffset > 0)
        this.element.classList.remove('d-none')
      else
        this.element.classList.add('d-none')
    })
  }
}
