import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['searchInput', 'textItem', 'moreLink'];

  connect() {
    this.bindSearchChanged()
    if (this.hasMoreLinkTarget)
      this.bindMoreClicked()
  }

  bindMoreClicked() {
    this.moreLinkTarget.addEventListener('click', () => {
      this.textItemTargets.forEach(item => {
        item.classList.remove('d-none')
        item.classList.add('d-flex')
      })
      this.moreLinkTarget.classList.add('d-none')
    })
  }

  bindSearchChanged() {
    this.searchInputTarget.addEventListener('keyup', () => {
      let searchText = this.searchInputTarget.value
      let regSearch = new RegExp(searchText, 'ig')
      if (!!searchText.length &&this.hasMoreLinkTarget) {
        this.moreLinkTarget.classList.add('d-none')
      }
      this.textItemTargets.forEach(item => {
        let pos = item.textContent.search(regSearch)
        if (pos > -1) {
          let matchedString = item.textContent.slice(pos, pos + searchText.length);
          let html = item.textContent.replace(matchedString, `<span class='bg-warning'>${matchedString}</span>`)
          item.querySelector('.small').innerHTML = html
          item.classList.remove('d-none')
          item.classList.add('d-flex')
        } else {
          item.classList.remove('d-flex')
          item.classList.add('d-none')
        }
      })
    })
  }
}
