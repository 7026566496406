import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        email: {
          email: true,
          required: true
        }
      }
    })
  }
}
