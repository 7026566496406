import { Controller } from '@hotwired/stimulus'
import { each, filter } from 'lodash';

export default class extends Controller {
  connect() {
    $(this.element).on('ajax:beforeSend', (e) => {
      if (this.invalid())
        return false
    })
  }

  invalid() {
    let error = false
    each($(this.element).find('.nested-room-images'), function(elm) {
      $(elm).find('.error-name, .error-image').text('')
      const hiddenField = $(elm).find('input[type="hidden"]')
      const textField = $(elm).find('input[type="text"]')
      if (filter([hiddenField, textField], elmField => !elmField.val()).length != 1)
        return;

      error = true
      if (!hiddenField.val()) {
        $(elm).find('.error-image').text('Missing image')
      }
      if (!textField.val()) {
        $(elm).find('.error-name').text('Missing name')
      }
    })
    return error
  }
}
