import { Controller } from '@hotwired/stimulus'
import * as PANOLENS from 'panolens'
import * as THREE from 'three'
import { find, each, keys, reduce, any } from 'lodash'

export default class extends Controller {
  static targets = ['editLink']
  static values = {
    begining: String,
    panoramas: Array,
    associations: Object,
    group: Object
  }

  connect() {
    this.setUp()
  }

  setUp() {
    this.viewer = new PANOLENS.Viewer({ autoHideInfospot: false })
    this.panoramaInstances = this.buildImagePanoramaInstances()
    this.assignPanoramaLinks()
    each(keys(this.panoramaInstances), key => this.viewer.add(this.panoramaInstances[key]))
  }

  buildImagePanoramaInstances() {
    return reduce(this.panoramasValue, (acc, pano) => {
      acc[pano.id] = this.getImagePano(pano)
      return acc
    }, {})
  }

  assignPanoramaLinks() {
    each(keys(this.associationsValue), key => {
      const associationItems = this.associationsValue[key]
      each(associationItems, item => {
        const { start_panorama_id, end_panorama_id, x, y, z } = item
        this.panoramaInstances[start_panorama_id].link(
          this.panoramaInstances[end_panorama_id],
          new THREE.Vector3(x,y,z)
        )
      })
    })
  }

  getImagePano(pano) {
    let instance = new PANOLENS.ImagePanorama(pano.image.url)
    instance.animationDuration = 200
    instance.addEventListener('click', e => {
      const { x, y, z } = this.viewer.raycaster.intersectObject(instance, true)[0].point
      const position = { x: -x, y, z }
      console.log(position)
    })
    if (this.hasEditLinkTarget) {
      instance.addEventListener('enter', e => {
        this.editLinkTarget.href = `/${I18n.locale}/panorama_groups/${this.groupValue.id}/panoramas/${pano.id}/edit`
      })
    }
    return instance
  }
}
