import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['parent', 'child'];

  connect() {
    this.parentTargets.forEach(el => this.bindParentEvent(el));
    this.childTargets[0].classList.remove('d-none');
    this.childTargets[0].classList.add('d-flex');
  }

  bindParentEvent(el) {
    el.addEventListener('change', (e) => {
      let catId = e.target.dataset.catId;
      let foundChild = this.childTargets.find(c => c.dataset.catId === catId);
      this.childTargets.forEach(c => {
        c.classList.remove('d-flex');
        c.classList.add('d-none');
      })
      if (!!foundChild) {
        foundChild.classList.remove('d-none');
        foundChild.classList.add('d-flex');
      }
    })
  }
}
