import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['widthInput', 'heightInput', 'fixedInput', 'storeviewInput']
  static values = {
    width: Number,
    height: Number,
    mobileColumns: Number,
    desktopColumns: Number,
    defaultStoreViewCode: String
  }

  connect() {
    this.changingTimeout = null
  }

  changeWidth(e) {
    this.widthValue = e.target.value
  }

  changeHeight(e) {
    this.heightValue = e.target.value
  }

  changeMobileColumns(e) {
    this.mobileColumnsValue = e.target.value
  }

  changeDesktopColumns(e) {
    this.desktopColumnsValue = e.target.value
  }

  changeStoreview() {
    this.updateIframe()
  }

  defaultStoreViewCodeValueChanged() {
    this.updateIframe()
  }

  toggleSizes(e) {
    this.widthInputTarget.disabled = !e.target.checked
    this.heightInputTarget.disabled = !e.target.checked
    this.updateIframe()
  }

  widthValueChanged() {
    if (!this.fixedInputTarget.checked) return;
    clearTimeout(this.changingTimeout)
    this.changingTimeout = setTimeout(() => this.updateIframe(), 500)
  }

  heightValueChanged() {
    if (!this.fixedInputTarget.checked) return;
    clearTimeout(this.changingTimeout)
    this.changingTimeout = setTimeout(() => this.updateIframe(), 500)
  }

  mobileColumnsValueChanged() {
    clearTimeout(this.changingTimeout)
    this.changingTimeout = setTimeout(() => this.updateIframe(), 500)
  }

  desktopColumnsValueChanged() {
    clearTimeout(this.changingTimeout)
    this.changingTimeout = setTimeout(() => this.updateIframe(), 500)
  }

  updateIframe() {
    $(this.element).trigger('submit.rails')
  }
}
