import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: String
  }
  static targets = ['image', 'text']

  connect() {
    if (!this.idValue) return;

    $.ajax({
      url: `/unsplashes/${this.idValue}`,
      method: 'GET',
      success: response => {
        let { urls, user } = response
        if (!urls || !user) {
          if (this.hasImageTarget) this.imageTarget.remove()
          if (this.hasTextTarget) this.textTarget.remove()
          return
        }

        if (this.hasImageTarget)
          this.imageTarget.src = response.urls.thumb

        if (this.hasTextTarget)
          this.textTarget.innerHTML = `
            Photo by <a target="_blank" href="${response.user.links.html}">${response.user.name}</a>
          `
      }
    })
  }
}
