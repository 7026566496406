import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        current_password: {
          required: true
        },
        new_password: {
          required: true
        },
        confirm_password: {
          required: true,
          equalTo: '#new_password'
        }
      }
    })
  }
}
