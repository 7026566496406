import { Controller } from '@hotwired/stimulus'
import { each } from 'lodash'

export default class extends Controller {
  static targets = ['fileInput', 'form']

  connect() {
    this.bindFileChanged()
  }

  bindFileChanged() {
    this.fileInputTarget.addEventListener('change', e => {
      let imageCount = document.querySelectorAll(`[name^='message[message_images_attributes]']`).length
      each(e.target.files, (file, index) => {
        const name = `message[message_images_attributes][${index + imageCount}][image]`
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let input = `
            <input type='hidden' name='${name}' id='${name}' value='${reader.result}' />
          `
          let imageTag = `
            <div class='bg-cover bg-no-repeat rounded overflow-hidden position-relative border border-secondary' style='background-image:url(${reader.result});width:80px;height:80px;'></div>
          `
          this.formTarget.insertAdjacentHTML('afterbegin', input)
          document.getElementById('message-images').insertAdjacentHTML('afterbegin', imageTag)
        }
      })
    })
  }
}
