import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    autocompleteUrl: String
  }

  connect() {
    this.validateForm()
  }

  validateForm() {
    $('.new_special_message').validate({
      errorPlacement: function(error, element) {
        if (element.hasClass('form-check-input')) {
          error.insertBefore(element.parent())
        } else {
          error.insertAfter(element)
        }
      },
      rules: {
        'autoComplete': {
          required: () => $('#special_message_message_type').prop('checked')
        },
        'special_message[design_status]': {
          required: () => $('#special_message_message_type').prop('checked')
        },
        'special_message[message]': {
          required: true
        }
      }
    });
  }
}
