import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'selector'];

  connect() {
    this.bindChangeEvent();
  }

  bindChangeEvent() {
    this.selectorTarget.addEventListener('change', () => {
      let value = this.selectorTarget.value
      if (!value) return;

      if (!confirm(I18n.t('js.common.are_you_sure'))) {
        this.selectorTarget.value = '';
        return;
      }

      this.formTarget.submit();
    })
  }
}
