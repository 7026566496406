import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['entries', 'pagination'];
  static values = {
    url: String
  };

  connect() {
    $.getJSON(this.urlValue).then(response => {
      this.entriesTarget.innerHTML = response.entries;
      this.paginationTarget.innerHTML = response.pagination;
    })
  }
}
