import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['coverPreview', 'fileInput', 'inputWrap', 'submitWrap'];

  connect() {
    this.initBackground = this.coverPreviewTarget.style.backgroundImage
    this.bindFileChangeEvent()
  }

  bindFileChangeEvent() {
    this.fileInputTarget.addEventListener('change', e => {
      const file = e.target.files[0];
      if (!!file) {
        this.submitMode()
        let reader = new FileReader()
        reader.onload = e => this.coverPreviewTarget.style.backgroundImage = `url(${e.target.result})`
        reader.readAsDataURL(file);
      }
    })
  }

  inputMode() {
    this.inputWrapTarget.classList.remove('d-none')
    this.inputWrapTarget.classList.add('d-block')
    this.submitWrapTarget.classList.remove('d-block')
    this.submitWrapTarget.classList.add('d-none')
  }

  submitMode() {
    this.inputWrapTarget.classList.remove('d-block')
    this.inputWrapTarget.classList.add('d-none')
    this.submitWrapTarget.classList.remove('d-none')
    this.submitWrapTarget.classList.add('d-block')
  }

  resetForm() {
    this.inputMode()
    this.fileInputTarget.value = ''
    this.coverPreviewTarget.style.backgroundImage = this.initBackground
  }
}
