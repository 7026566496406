import {
  Controller
} from "@hotwired/stimulus";
import { map, each } from 'lodash'

export default class extends Controller {
  static values = {
    urlSearch: String,
    placeholder: String,
    tags: {
      type: Boolean,
      default: true
    },
    defaultTagItems: {
      type: Array
    }
  }

  connect() {
    this.setUpSelect2();
    if (!!this.defaultTagItemsValue)
      this.assignsOptions()
    $(this.element).on('select2:selecting', e => {
      let id = e.params.args.data.id;
      if (/^\d+$/.test(id) && (typeof id === 'string' || id instanceof String)) {
        alert('Cannot enter a number only!')
        e.preventDefault()
      }
    })
  }

  assignsOptions() {
    each(this.defaultTagItemsValue, data => {
      if ($(this.element).find("option[value='" + data.id + "']").length) {
        $(this.element).val(data.id).trigger('change');
      } else {
        // Create a DOM Option and pre-select by default
        var newOption = new Option(data.name, data.id, true, true);
        // Append it to the select
        $(this.element).append(newOption).trigger('change');
      }
    })
  }

  setUpSelect2() {
    $(this.element).select2({
      width: '100%',
      multiple: true,
      maximumSelectionLength: Infinity,
      tokenSeparators: [','],
      tags: this.tagsValue,
      closeOnSelect: true,
      minimumInputLength: 2,
      placeholder: this.placeholderValue,
      ajax: {
        url: this.urlSearchValue,
        dataType: 'json',
        processResults: function(data) {
          let results = map(data.results, item => ({ ...item, text: item.name }))
          return {
            results
          }
        }
      }
    })
  }
}
