import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dropdownMenu', 'button'];
  static values = {
    show: Boolean
  }

  toggle() {
    this.showValue = !this.showValue
  }

  showValueChanged() {
    this.showValue ? this.activeState() : this.inactiveState()
  }

  activeState() {
    this.dropdownMenuTarget.classList.remove('d-none')
  }

  inactiveState() {
    this.dropdownMenuTarget.classList.add('d-none')
  }
}
