import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'results', 'loading', 'clearBtn'];
  static values = {
    url: String,
    createUrl: String
  }

  connect() {
    this.timeout = null;
    this.results = ''
  }

  emptyResults() {
    this.resultsTarget.innerHTML = '';
    this.hideResults();
    this.results = '';
    this.hideClearBtn();
  }

  showResults() {
    if (this.results === 'No Results') {
      this.emptyResults();
      return;
    }
    this.resultsTarget.classList.remove('d-none');
    this.resultsTarget.innerHTML = this.results;
  }

  hideResults() {
    this.resultsTarget.classList.add('d-none');
  }

  showClearBtn() {
    this.clearBtnTarget.classList.remove('d-none');
  }

  hideClearBtn() {
    this.clearBtnTarget.classList.add('d-none');
  }

  emptyInput() {
    this.inputTarget.value = ''
  }

  clearResults() {
    this.emptyInput();
    this.emptyResults();
    this.hideResults();
    this.hideClearBtn();
  }

  disabledInput(disable) {
    if (disable)
      this.inputTarget.setAttribute('disabled', true);
    else
      this.inputTarget.removeAttribute('disabled');
  }

  adding(e) {
    let index = e.target.dataset.index;
    this.disabledInput(true);
    $.ajax({
      url: this.createUrlValue,
      method: 'POST',
      dataType: 'json',
      data: {
        design_version_element: {
          index: e.target.dataset.index
        }
      }
    }).then(response => {
      if (response.success) {
        this.emptyResults();
        this.inputTarget.value = '';
        this.insertToItemList(response.entity)
      } else {
        alert('This material already added!')
      }
      this.disabledInput(false);
    })
  }

  insertToItemList(entity) {
    let controller = this.application.controllers.find(controller => controller.context.identifier === 'my-catalog-items')
    if (!controller) return;

    controller.entriesTarget.insertAdjacentHTML('afterbegin', entity);
  }

  search() {
    let params = new URLSearchParams();
    params.append('term', this.inputTarget.value);
    let url = `${this.urlValue}?${params.toString()}`;
    this.loadingTarget.classList.remove('d-none');
    this.showClearBtn();
    $.getJSON(url).then(response => {
      this.loadingTarget.classList.add('d-none');
      this.results = response.entities.trim() || 'No Results';
      this.showResults()
    })
  }

  inputChanged() {
    clearTimeout(this.timeout);
    if (!this.inputTarget.value) {
      this.emptyResults();
    } else {
      this.timeout = setTimeout(() => this.search(), 1000);
    }
  }
}
