import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['filterContainer', 'themeCheckBoxes', 'categoryCheckBoxes', 'entries', 'pagination', 'loading'];
  static values = { url: String, categories: Array }

  connect() {
    this.setDefault();
  }

  setDefault() {
    this.categoriesValue.forEach(cat => {
      this.categoryCheckBoxesTarget.querySelector(`[value='${cat}']`).setAttribute('checked', 'checked')
    })
  }

  close() {
    this.filterContainerTarget.classList.add('d-none');
    this.filterContainerTarget.classList.remove('d-flex');
  }

  open() {
    this.filterContainerTarget.classList.remove('d-none');
    this.filterContainerTarget.classList.add('d-flex');
  }

  selectedThemes() {
    return Array.from(this.themeCheckBoxesTarget.querySelectorAll(':checked')).map(selector => selector.value);
  }

  selectedCategories() {
    return Array.from(this.categoryCheckBoxesTarget.querySelectorAll(':checked')).map(selector => selector.value);
  }

  showLoading() {
    this.loadingTarget.classList.remove('d-none');
  }

  hideLoading() {
    this.loadingTarget.classList.add('d-none');
  }

  disabledAll() {
    Array.from(this.themeCheckBoxesTarget.querySelectorAll('input')).forEach(selector => selector.setAttribute('disabled', true));
    Array.from(this.categoryCheckBoxesTarget.querySelectorAll('input')).forEach(selector => selector.setAttribute('disabled', true));
  }

  enableAll() {
    Array.from(this.themeCheckBoxesTarget.querySelectorAll('input')).forEach(selector => selector.removeAttribute('disabled'));
    Array.from(this.categoryCheckBoxesTarget.querySelectorAll('input')).forEach(selector => selector.removeAttribute('disabled'));
  }

  changed() {
    let url = new URL(this.urlValue);
    this.selectedThemes().forEach(theme => url.searchParams.append('themes[]', theme));
    this.selectedCategories().forEach(cat => url.searchParams.append('categories[]', cat));
    this.disabledAll();
    this.showLoading();
    $.getJSON(url).then(response => {
      this.entriesTarget.innerHTML = response.entries;
      this.paginationTarget.innerHTML = response.pagination;
      this.enableAll();
      this.hideLoading();
    })
  }
}
