import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['fileInput', 'clickableLabel', 'clImage'];
  static values = {
    url: String
  }

  connect() {
    this.bindChangeEvent();
    this.bindClickEvent();
  }

  bindChangeEvent() {
    this.fileInputTarget.addEventListener('change', (e) => {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      this.showLoadingState();
      reader.onload = () => {
        this.submit(reader.result)
      }
    })
  }

  bindClickEvent() {
    this.clickableLabelTargets.forEach(elm => elm.addEventListener('click', () => this.fileInputTarget.click()))
  }

  showLoadingState() {
    this.clickableLabelTargets.forEach(elm => elm.querySelector('.fa-spinner').classList.remove('d-none'))
  }

  hideLoadingState() {
    this.clickableLabelTargets.forEach(elm => elm.querySelector('.fa-spinner').classList.add('d-none'))
  }

  submit(base64) {
    return $.ajax({
      url: this.urlValue,
      dataType: 'json',
      method: 'POST',
      data: {
        item_set: {
          base64_thumbnail_image: base64
        }
      }
    }).then(res => {
      this.clImageTargets.forEach(elm => {
        let url = res.image_url
        elm.setAttribute('src', url)
        document.getElementById(`item_set_${res.id}`).querySelectorAll('.bg-cover').forEach(elm => {
          elm.style.backgroundImage = `url(${url})`
        })
      })
    }).then(() => this.hideLoadingState())
  }
}
