import { Controller } from '@hotwired/stimulus';
import axios from 'axios'

export default class extends Controller {
  connect() {
    this.validator = this.validateForm();
    this.keyUpTimeout = null;
    this.requested = false;
  }

  search() {
    this.validator.element('#email')
    if (!$('#email').val() || !!$('#email.error').length) return;

    this.searching();
    axios.post('/custom_items/search_member.json', { email: $('#email').val() })
      .then(response => {
        if (!!response.data.existing) {
          $('.hidable').hide()
          $('#btn-login').removeClass('d-none')
        } else {
          $('.hidable').show()
          $('#btn-login').addClass('d-none')
        }
      })
      .finally(() => {
        this.requested = true
        this.finishSearching()
      })
  }

  searching() {
    $('#loading').removeClass('d-none')
    $('input, textarea, button, select').prop('disabled', true)
  }

  finishSearching() {
    $('#loading').addClass('d-none')
    $('input, textarea, button, select').prop('disabled', false)
  }

  onKeyUp() {
    clearTimeout(this.keyUpTimeout)
    this.keyUpTimeout = setTimeout(() => this.search(), 3000);
  }

  onBlur() {
    if (this.requested) return;

    clearTimeout(this.keyUpTimeout);
    this.search();
  }

  onFocus() {
    this.requested = false
  }

  validateForm() {
    return $(this.element).validate({
      rules: {
        email: {
          email: true,
          required: true
        },
        first_name: {
          required: {
            depends: element => {
              return $('#email.error')
            }
          }
        },
        last_name: {
          required: {
            depends: element => {
              return $('#email.error')
            }
          }
        }
      },
      showErrors: function(errorMap, errorList) {
        if (Object.keys(errorMap).indexOf('email') > -1) {
          $('.hidable').show()
          $('#btn-login').addClass('d-none')
        }
        this.defaultShowErrors();
      },
    })
  }
}
