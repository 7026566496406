import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    email: String
  }

  connect() {
    $(this.element).validate({
      rules: {
        current_password: {
          required: true
        },
        new_email: {
          required: true
        }
      }
    })
  }
}
