import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    urlSearch: String,
    placeholder: String
  }

  connect() {
    this.setUpSelect2();
  }

  isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str)
  }

  setUpSelect2() {
    $(this.element).select2({
      width: '100%',
      language: I18n.locale,
      minimumInputLength: 1,
      allowClear: true,
      dropdownParent: $(this.element).parent(),
      placeholder: this.hasPlaceholderValue ? this.placeholderValue : I18n.t('js.room_designs.search_member_placeholder'),
      ajax: {
        url: this.urlSearchValue,
        dataType: 'json',
        data: (params) => {
          if (params.term.trim().length == 1 && this.isASCII(params.term.trim())) {
            throw "1 ASCII charactor";
          }
          return {
            term: params.term.trim(),
            page: params.page || 1
          };
        },
        processResults: (data) => {
          return {
            results: data.results,
            pagination: data.pagination
          }
        }
      },
      templateResult: (state) => {
        let current = $('.select2-search__field').val()
        if (!!current && current.trim().length == 1 && this.isASCII(current.trim())) {
          return I18n.t('js.room_designs.search_member_term_short', { count: 1 })
        }
        return state.html || state.text
      },
      escapeMarkup: (markup) => {
        return markup;
      }
    })
  }
}
