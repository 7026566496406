import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    link: String
  }

  static targets = ['link', 'storeview', 'copyButton']

  connect() {
    this.url = new URL(this.linkValue)
  }

  updateLink() {
    if (this.hasStoreviewTarget) {
      this.url.searchParams.set('store_view_code', this.storeviewTarget.value)
    }
    this.linkTarget.innerHTML = this.url.toString()
    this.copyButtonTarget.dataset.clipboardTextValue = this.url.toString()
  }
}
