import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    text: String
  };

  connect() {
    this.timeout = null;
  }

  copy() {
    this.exec().then(() => {
      if (!!this.element.dataset.tooltip) {
        clearTimeout(this.timeout)
        this.element.dataset.tooltip = 'Copied'
        this.timeout = setTimeout(() => {
          this.element.dataset.tooltip = 'Copy'
        }, 5000)
      } else {
        alert('Copied')
      }
    })
  }

  exec() {
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(this.textValue);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = this.textValue;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }
}
