import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['createButton', 'ids']

  connect() {
    this.products = []
  }

  updateProducts(e) {
    if (e.target.checked) {
      this.products.push(e.target.value)
    } else {
      this.products.splice(this.products.indexOf(e.target.value), 1)
    }
    this.updateButton()
  }

  updateButton() {
    this.createButtonTarget.disabled = !this.products.length
    this.idsTarget.value = this.products
  }
}
