import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.setUpSelect2()
  }

  setUpSelect2() {
    $(this.element).select2({ width: '100%' })
  }
}
