import { Controller } from '@hotwired/stimulus'
import { find } from 'lodash'

export default class extends Controller {
  static targets = ['navTabs']
  static classes = ['active']
  static values = {
    wrapperId: String,
    openTab: String
  }

  connect() {
    this.currentTab = null
    this.bindTabsClicked()
    if (!!this.wrapperIdValue) this.bindOutsideClicked()
    if (!!this.openTabValue) {
      let tab = find(this.navTabsTargets, el =>
        el.dataset.contentid === this.openTabValue
      )
      this.onTabClicked(tab)
    }
  }

  allContentIds() {
    return this.navTabsTargets.map(tab => tab.dataset.contentid)
  }

  bindTabsClicked() {
    this.navTabsTargets.forEach(tab => {
      tab.addEventListener('click', (e) => {
        e.stopPropagation()
        this.onTabClicked(tab)
      })
    })
  }

  bindOutsideClicked() {
    document.addEventListener('click', (e) => {
      if (
        !!$(e.target).parents(this.wrapperIdValue).length ||
        `#${$(e.target).prop('id')}` === this.wrapperIdValue) {
        return;
      }
      this.onTabClicked(null)
    })
  }

  onTabClicked(tab) {
    this.navTabsTargets.forEach(navTab => navTab.classList.remove(...this.activeClasses))
    const newCurrent = tab && tab.dataset.contentid
    if (this.currentTab === newCurrent) {
      this.currentTab = null
    } else {
      this.currentTab = newCurrent
      tab && tab.classList.add(...this.activeClasses)
    }
    this.toggle()
  }

  toggle() {
    this.allContentIds().forEach(id => {
      const elm = document.querySelector(id)
      elm.classList.add('d-none')
    })
    if (!!this.currentTab) {
      document.querySelector(this.currentTab).classList.remove('d-none')
    }
  }
}
