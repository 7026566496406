import { Controller } from "@hotwired/stimulus";
import { each, map, reverse } from 'lodash'

export default class extends Controller {
  static targets = ['video', 'control', 'editLink']

  static values = {
    presentationPauseTimes: Array,
    pauseTimePins: Object
  }

  connect() {
    this.endTimeIdx = 0
    this.inverval = null
    this.pauseTimes = this.getPauseTimes()
    this.setCurrentPauseTime()

    if (this.pauseTimes.indexOf('0') < 0 && this.pauseTimes.indexOf(0) < 0) {
      this.pauseTimes = [0, ...this.pauseTimes]
    }

    this.videoTarget.load()

    this.videoTarget.addEventListener('loadeddata', () => {
      this.element.classList.remove('d-none')
    })
  }

  getPauseTimes() {
    return map(this.presentationPauseTimesValue, obj => obj.milliseconds)
  }

  setCurrentPauseTime() {
    this.currentPauseTime = this.presentationPauseTimesValue[this.endTimeIdx]
  }

  flyNext() {
    this.hideAllPins();
    this.endTimeIdx++
    if (this.endTimeIdx >= this.pauseTimes.length) {
      this.endTimeIdx = 0
    }
    this.setCurrentPauseTime()
    this.originalNext()
  }

  hideControls() {
    each(this.controlTargets, cont => cont.style.display = 'none')
  }

  showControls() {
    each(this.controlTargets, cont => cont.style.display = 'block')
  }

  originalNext() {
    clearInterval(this.inverval)
    this.videoTarget.play()
    this.hideControls()
    this.inverval = setInterval(() => {
      let currrentVideoTime = this.videoTarget.currentTime * 1000
      if (
        (!this.endTimeIdx && currrentVideoTime >= (this.videoTarget.duration * 1000)) ||
        (!!this.endTimeIdx && currrentVideoTime >= this.pauseTimes[this.endTimeIdx])
      ) {
        this.videoTarget.pause();
        clearInterval(this.inverval)
        if (this.hasEditLinkTarget) this.setEditLink()
        this.showControls()
        if (!this.endTimeIdx) this.videoTarget.currentTime = 0
        this.showPins()
      }
    }, 100)
  }

  setEditLink() {
    const locale = I18n.locale
    const {
      presentation_video_id: presentationVideoId,
      id: presentationPauseTimeId
    } = this.currentPauseTime
    this.editLinkTarget.setAttribute(
      'href',
      `/${locale}/presentation_videos/${presentationVideoId}/presentation_pause_times/${presentationPauseTimeId}/edit`
    )
  }

  showPins() {
    this.hideAllPins()
    each(document.querySelectorAll(`.marker-pin.pause_time_${this.currentPauseTime.id}`), elm => elm.classList.remove('d-none'))
  }

  hideAllPins() {
    each(document.querySelectorAll('.marker-pin'), elm => elm.classList.add('d-none'))
  }
}
