import { Controller } from '@hotwired/stimulus'
import * as PANOLENS from 'panolens'
import { each } from 'lodash'

export default class extends Controller {
  static targets = ['editPanel']
  static values = {
    panoramaRecord: Object,
    pins: Array,
    clickEnable: {
      type: Boolean,
      default: true
    }
  }

  connect() {
    this.viewer = new PANOLENS.Viewer({ autoHideInfospot: false })
    this.pano = new PANOLENS.ImagePanorama(this.panoramaRecordValue.image.url);
    this.showExistedPins()
    if (!!this.clickEnableValue) {
      this.pano.addEventListener('click', e => {
        this.removeAllNewInfospot()
        const { x, y, z } = this.viewer.raycaster.intersectObject(this.pano, true)[0].point
        let spot = this.getInfospot(-x, y, z)
        spot.show()
        this.pano.add(spot)
        window.currentSpot = spot
        this.requestNewLink(-x.toFixed(2), +y.toFixed(2), +z.toFixed(2))
      })
    }
    this.viewer.add(this.pano)
  }

  getInfospot(x,y,z) {
    let spot = new PANOLENS.Infospot()
    spot.position.set(x,y,z)
    spot.isNew = true
    return spot
  }

  requestNewLink(x,y,z) {
    const url = `/${I18n.locale}/panoramas/${this.panoramaRecordValue.id}/panorama_links/new.js?x=${x}&y=${y}&z=${z}`
    $.ajax({
      url,
      type: "GET",
      processData: false,
      contentType: false,
      dataType: 'script'
    });
  }

  hideEditPanel() {
    this.editPanelTarget.classList.remove('active')
    this.removeAllNewInfospot()
  }

  removeAllNewInfospot() {
    each(this.pano.children, child => {
      if (child instanceof PANOLENS.Infospot && child.isNew) {
        child.dispose()
      }
    })
  }

  showExistedPins() {
    each(this.pinsValue, pin => {
      const { x, y, z, hover_text: hoverText } = pin
      let infospot = this.getInfospot(x,y,z)
      infospot.addHoverText(hoverText)
      this.pano.add(infospot)
    })
  }
}
