import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    products: Array,
    label: String
  }

  connect() {
    window.addEventListener('message', e => {
      console.log(e)
      if (!Object.keys(e.data).length) return;
      const { func, message } = e.data
      if (func !== 'addToCartResultFromIframe') return;
      if (!!message.success) {
        this.element.innerHTML = 'Added'
      } else {
        this.element.disabled = false
        this.element.innerHTML = this.labelValue || 'Add to cart'
      }
    })
  }

  clicked(e) {
    e.target.disabled = true;
    e.target.innerHTML = '<i class="fa fa-spinner spin"></i>';
    console.log('postMessage: addToCartFromIframe')
    console.log({ products: this.productsValue })
    window.parent.postMessage(
      {
        func: "addToCartFromIframe",
        message: { products: this.productsValue },
      },
      "*"
    );
  }
}
