import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  expand(e) {
    this.reset()
    $(`.elements_${e.params.id}`).removeClass('d-none')
    $(`#${e.params.id}`).addClass('d-none')
  }

  reset() {
    $(".element").addClass('d-none')
    $(".family").removeClass('d-none')
  }
}
