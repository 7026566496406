import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    $.getJSON(this.urlValue).then(rs => $(this.element).html(rs.results))
  }
}
