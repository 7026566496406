import { Controller } from "@hotwired/stimulus";
import { filter, each } from 'lodash';

export default class extends Controller {
  static targets = ["entries", "pagination"];
  static values = {
    triggerHeight: { type: Number, default: 500 }
  }

  connect() {
    // prevents too many requests to API server with the same page params while scrolling
    this.allowRequest = true;
    this.observer = new IntersectionObserver((entries) => {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      if (entries[0].isIntersecting) {
        console.log('Element has just become visible in screen');
        if (this.allowRequest) {
          this.allowRequest = false;
          this.loadMore(() => (this.allowRequest = true));
        }
      }
    }, { threshold: [0] });
    this.bindObserver();
    let nextLink = this.paginationTarget.querySelector('a[rel="next"]');
    if (!!nextLink)
      this.paginationTarget.insertAdjacentHTML('afterend', "<div id='infinited-scrolling-loader' class='text-center h5'><i class='fa fa-spinner spin'></i></div>")
  }

  bindObserver() {
    let observers = document.querySelectorAll('.observe-entity')
    if (!observers) return;
    each(observers, observer => this.observer.observe(observer))
  }

  scroll() {
    let body = document.body,
      html = document.documentElement;
    let height = Math.max(body.scrollHeight, body.offsetHeight, html.scrollHeight, html.offsetHeight);
    if (
      window.scrollY >= height - window.innerHeight - this.triggerHeightValue &&
      this.allowRequest
    ) {
      this.allowRequest = false;
      this.loadMore(() => (this.allowRequest = true));
    }
  }

  loadMore(cb = null) {
    let nextLink = this.paginationTarget.querySelector('a[rel="next"]');
    if (!nextLink) {
      this.allowRequest = true;
      let loader = document.getElementById('infinited-scrolling-loader');
      if (!!loader) loader.remove()
      return;
    }

    let url = nextLink.href;
    let isRemote = nextLink.dataset.remote
    if (!!isRemote) {
      nextLink.click();
      each(this.paginationTarget.querySelectorAll('a[rel="next"]'), elm => elm.remove())
      this.allowRequest = true;
    } else {
      $.getJSON(url, response => {
        this.entriesTarget.insertAdjacentHTML("beforeend", response.entries);
        this.paginationTarget.innerHTML = response.pagination;

        // if partial had js, we should execute it
        let scripts = []
        $(response.entries).each(function() {
          if ($(this).is('script'))
            scripts.push(this)
        })
        if (!!scripts.length) {
          Array.from(scripts).forEach( oldScript => {
            const newScript = document.createElement("script");
            Array.from(oldScript.attributes)
              .forEach( attr => newScript.setAttribute(attr.name, attr.value) );
            newScript.appendChild(document.createTextNode(oldScript.innerHTML));
            oldScript.parentNode.replaceChild(newScript, oldScript);
          });
        }

        if (cb) cb()
      })
    }
  }
}
