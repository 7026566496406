import { Controller } from '@hotwired/stimulus'
import { each, find } from 'lodash'

export default class extends Controller {
  static targets = ['isOpenTab', 'isHideTab', 'isOpenProduct', 'openTab', 'hideTab', 'hideMultiSlots', 'openProduct', 'link', 'hidePrices', 'showAddCart', 'copyButton', 'lang'];
  static values = {
    link: String
  }

  connect() {
    this.url = new URL(this.linkValue)
  }

  onOpenTabChanged(e) {
    each(this.openTabTargets, elm => {
      elm.disabled = !e.target.checked
    })
    this.updateLink()
  }

  onHideTabChanged(e) {
    each(this.hideTabTargets, elm => {
      elm.disabled = !e.target.checked
    })
    this.updateLink()
  }

  onOpenProductChanged(e) {
    each(this.openProductTargets, elm => {
      elm.disabled = !e.target.checked
    })
    this.updateLink()
  }

  updateLink() {
    if (this.hasLangTarget) {
      let val = find(this.langTargets, elm => elm.checked)
      if (!!val)
        this.url.searchParams.set('lang', val.value)
    } else {
      this.url.searchParams.delete('lang')
    }

    if (this.hasIsOpenTabTarget && this.isOpenTabTarget.checked) {
      let val = find(this.openTabTargets, elm => elm.checked)
      this.url.searchParams.set('open_tab', val.value)
    } else {
      this.url.searchParams.delete('open_tab')
    }

    if (this.hasIsHideTabTarget && this.isHideTabTarget.checked) {
      let val = find(this.hideTabTargets, elm => elm.checked)
      this.url.searchParams.set('hide_tab', val.value)
    } else {
      this.url.searchParams.delete('hide_tab')
    }

    if (this.hasIsOpenProductTarget && this.isOpenProductTarget.checked) {
      let val = find(this.openProductTargets, elm => elm.checked)
      this.url.searchParams.set('open_design_id', val.value)
    } else {
      this.url.searchParams.delete('open_design_id')
    }

    if (this.hidePricesTarget.checked) {
      this.url.searchParams.set('hide_prices', 1)
    } else {
      this.url.searchParams.delete('hide_prices')
    }

    if (this.showAddCartTarget.checked) {
      this.url.searchParams.set('show_add_cart', 1)
    } else {
      this.url.searchParams.delete('show_add_cart')
    }

    if (this.hasHideMultiSlotsTarget && this.hideMultiSlotsTarget.checked) {
      this.url.searchParams.set('hide_multi_slots', 1)
    } else {
      this.url.searchParams.delete('hide_multi_slots')
    }

    this.linkTarget.innerHTML = this.url.toString()
    this.copyButtonTarget.dataset.clipboardTextValue = this.url.toString()
  }
}
