import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String
  }

  click() {
    $(this.element).modal('hide')
    window.location.href = this.urlValue
  }
}
