import 'controllers';

window.Pages['HomeApplication'] = {
  init() {
    this.hideMemberMenu()
  },

  hideMemberMenu() {
    document.body.addEventListener('click', function() {
      Pages['MemberShowPage'].toggleHeadMenu(null)
    })
  },

  pushLoginGTMEvent(memberId) {
    console.log(memberId)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'login',
      memberId: memberId
    })
  }
}

$(document).ready(function() {
  window.Pages['HomeApplication'].hideMemberMenu();
  GLightbox({
    preload: false,
    keyboardNavigation: false,
    loop: true
  });
});


window.Pages['MemberShowPage'] = {
  toggleHeadMenu(selector) {
    let submenus = ['.user', '.menu', '.noti', '.langs', '.currencies']
    submenus.forEach(menu => {
      if (menu === selector) return;
      $(menu).removeClass('d-flex')
      $(menu).addClass('d-none')
    })
    if (selector)
      $(selector).toggleClass('d-none d-flex')
  }
};

window.Pages['HomeIndexPage'] = {
  bindCarousel(wrapper = '') {
    document.querySelectorAll(wrapper + ' .carousel-arrow').forEach(arrow => {
      $(arrow).on('click', function() {
        let scrollLeft = $(this).parent().find('ol').scrollLeft()
        $(this).parent().find('ol').scrollLeft(scrollLeft + 55)
        console.log('clicked')
      })
    })
    document.querySelectorAll(wrapper + ' .carousel-arrow-rev').forEach(arrow => {
      $(arrow).on('click', function() {
        let scrollLeft = $(this).parent().find('ol').scrollLeft()
        $(this).parent().find('ol').scrollLeft(scrollLeft - 55)
      })
    })
    document.querySelectorAll(wrapper + ' .carousel-indicators').forEach(ol => {
      $(ol).on('scroll', function() {
        if ($(this).scrollLeft() > 0) {
          $(this).parent().find('.carousel-arrow-rev').removeClass('d-none')
        } else {
          $(this).parent().find('.carousel-arrow-rev').addClass('d-none')
        }
      })
    })
  },

  backToTop() {
    $(document).scrollTop(0)
  }
};
