import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    urlSearch: String
  }

  connect() {
    this.setUpSelect2()
  }

  setUpSelect2() {
    $(this.element).select2({
      width: '100%',
      language: I18n.locale,
      minimumInputLength: 2,
      allowClear: true,
      dropdownParent: $(this.element).parent(),
      placeholder: 'Search catalog by name or ID',
      ajax: {
        url: this.urlSearchValue,
        dataType: 'json',
        processResults: function(data) {
          return {
            results: data.results
          }
        }
      }
    })
  }
}
