import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['toggleBtn'];

  static values = {
    updateUrl: String,
    active: Boolean
  };

  connect() {
    this.active = this.activeValue
  }

  toggleButton() {
    this.toggleBtnTarget.classList.toggle('btn-outline-success', !this.active);
    this.toggleBtnTarget.classList.toggle('btn-outline-danger', this.active);
    this.toggleBtnTarget.innerHTML = this.active ? 'Deactivate' : 'Activate'
  }

  toggleActive(e) {
    this.active = !this.active;
    $.ajax({
      url: this.updateUrlValue,
      dataType: 'json',
      method: 'PATCH',
      data: {
        my_catalog_item: {
          active: this.active
        }
      }
    }).then(response => {
      if (response.success) this.toggleButton();
      else
        return Promise.reject();
    }).catch(() => alert('Fails to change active state'));
  }
}
