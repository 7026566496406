import { Controller } from "@hotwired/stimulus";
import { each } from 'lodash'

export default class extends Controller {
  static targets = ['video', 'editPanel', 'marker']
  static values = {
    presentationPauseTime: Object,
    marker: String
  }

  connect() {
    this.milliseconds = this.presentationPauseTimeValue.milliseconds
    this.videoTarget.currentTime = this.milliseconds / 1000
  }

  requestNew(e) {
    this.removeNewMarkers()
    const top = (e.pageY / document.body.clientHeight) * 100
    const left = (e.pageX / document.body.clientWidth) * 100
    if (this.hasEditPanelTarget) this.showEditPanel();
    if (this.hasMarkerTarget) {
      const clone = this.markerTarget.cloneNode(true)
      clone.classList.remove('d-none')
      clone.classList.add('new')
      clone.style.top = `${top}%`
      clone.style.left = `${left}%`
      this.element.append(clone)
    }

    const url = `/${I18n.locale}/presentation_pause_times/${this.presentationPauseTimeValue.id}/presentation_pins/new.js?top=${top}&left=${left}`
    $.ajax({
      url,
      type: "GET",
      processData: false,
      contentType: false,
      dataType: 'script'
    });
  }

  showEditPanel() {
    this.editPanelTarget.classList.add('active')
  }

  hideEditPanel() {
    this.editPanelTarget.classList.remove('active')
  }

  removeNewMarkers() {
    each(document.querySelectorAll('.marker-pin.new'), e => e.remove())
  }
}
