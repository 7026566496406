import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        'my_catalog[name]': {
          required: true
        },
        'my_catalog[status]': {
          required: true
        }
      }
    })
  }
}
