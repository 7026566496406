import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    scrollToActive: {
      type: Boolean,
      default: true
    }
  }

  connect() {
    if (this.isMobile()) return;
    this.tocWrapper = null
    this.articleContent = null
    this.navHeight = null
    this.footerHeight = null
    this.height = null
    this.interval = null
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      if (document.readyState !== 'complete') return;

      clearInterval(this.interval)
      this.tocWrapper = document.querySelector('.toc-wrapper')
      this.articleContent = document.querySelector('.article-content')
      this.navHeight = document.querySelector('.head').offsetHeight
      this.tocWrapper.style.position = 'relative'
      this.tocWrapper.style.height = `calc(100vh - ${this.navHeight}px)`
      this.articleContent.style.marginLeft = "0"

      this.footerHeight = document.querySelector('footer').offsetHeight
      this.height = document.querySelector('.whole-page').offsetHeight
      this.updateTOC()
      if (this.scrollToActiveValue) this.scrollToActive()
      document.addEventListener('scroll', e => {
        this.updateTOC()
      })
    }, 500)
  }

  isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  updateTOC() {
    console.log('updateTOC')
    if (pageYOffset >= 92) {
      this.tocWrapper.style.position = 'fixed'
      this.tocWrapper.style.top = '0'
      this.articleContent.style.marginLeft = "350px"
      if (pageYOffset + innerHeight >= this.height) {
        this.tocWrapper.style.height = `calc(100vh - ${pageYOffset + innerHeight - this.height}px)`
      } else {
        this.tocWrapper.style.height = '100vh'
      }
    } else {
      this.tocWrapper.style.position = 'relative'
      this.tocWrapper.style.height = `calc(100vh - ${this.navHeight}px)`
      this.articleContent.style.marginLeft = "0"
    }
  }

  scrollToActive() {
    let activeElm = document.querySelector(`a[href^="${location.pathname}"]`)
    if (!activeElm) {
      let paths = location.pathname.split('/')
      let shortNotionId = paths[paths.length - 1].replaceAll('-', '')
      activeElm = document.querySelector(`a[id="${shortNotionId}"]`)
    }
    activeElm.classList.add('toc-hightlight')
    document.querySelector(".toc-wrapper").scrollTop = activeElm.offsetTop - 300
  }
}
