import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['statusMessage', 'payButton']

  static values = {
    publicKey: String,
    returnUrl: String,
    getPaymentIntentUrl: String,
    paymentIntentClientSecret: String
  }

  connect() {
    this.stripe = Stripe(this.publicKeyValue)
    this.elements = null
    this.checkStatus()
    this.fetchPaymentIntent()
  }

  fetchPaymentIntent() {
    if (!this.getPaymentIntentUrlValue) return;

    let authenticity_token = document.querySelector('meta[name="csrf-token"]').content
    fetch(
      this.getPaymentIntentUrlValue,
      {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ authenticity_token: authenticity_token })
      }
    )
    .then(response => response.json())
    .then(results => {
      if (results['error'])
        return Promise.reject(results['error'])
      return Promise.resolve(results)
    })
    .then(({ clientSecret }) => this.buildElements(clientSecret))
    .then(() => {
      document.querySelector('.btn-pay').classList.remove('d-none')
    })
    .catch(e => alert(e))
  }

  payClicked() {
    this.payButtonTarget.disabled = true
    this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: this.returnUrlValue
      }
    }).then(result => {
      this.payButtonTarget.disabled = false
      console.log(result)
    })
  }

  buildElements(clientSecret) {
    this.elements = this.stripe.elements({
      locale: I18n.locale,
      appearance: {
        theme: 'flat'
      }, clientSecret
    })
    const paymentElement = this.elements.create("payment");
    return paymentElement.mount("#payment-element");
  }

  showMessage(text) {
    this.statusMessageTarget.innerHTML = text
  }

  checkStatus() {
    if (!this.paymentIntentClientSecretValue) {
      return;
    }

    this.stripe.retrievePaymentIntent(this.paymentIntentClientSecretValue)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            this.showMessage("Payment succeeded!");
            break;
          case "processing":
            this.showMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            this.showMessage("Your payment was not successful, please try again.");
            break;
          default:
            this.showMessage("Something went wrong.");
            break;
        }
      })
  }
}
