import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['mentions']

  static values = {
    id: String
  }

  connect() {
    if (!CKEDITOR.instances[this.idValue]) return;

    this.setMentions()
  }

  setMentions() {
    if (!this.hasMentionsTarget) return;

    CKEDITOR.instances[this.idValue].on('change', e => {
      let body = e.editor.document.getBody().getText()
      let mentions = body.match(/@[a-z0-9]+(?:-[a-z0-9]+)*/g)
      if (!mentions) return

      this.mentionsTarget.value = mentions.map(m => m.slice(1)).join(',')
    })
  }
}
