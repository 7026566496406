import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox']

  toggle() {
    [
      'opportunity_shipping_address_attributes_address_name',
      'opportunity_shipping_address_attributes_first_name',
      'opportunity_shipping_address_attributes_last_name',
      'opportunity_shipping_address_attributes_state',
      'opportunity_shipping_address_attributes_city',
      'opportunity_shipping_address_attributes_street',
      'opportunity_shipping_address_attributes_zip_code',
      'opportunity_shipping_address_attributes_telephone',
    ].forEach(id => {
      document.getElementById(id).disabled = this.checkboxTarget.checked
    })
  }
}
