import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['button']

  connect() {
    this.timeout = null
    this.bindHideResult()
  }

  onChanged(e) {
    this.disableButton()
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      $(this.element).submit()
    }, 800)
  }

  disableButton() {
    if (!this.hasButtonTarget) {
      return
    }
    this.buttonTarget.disabled = true
    this.buttonTarget.innerHTML = "<i class='fa fa-spinner spin'></i>"
  }

  bindHideResult() {
    document.addEventListener('click', (e) => {
      $('#results').addClass('d-none')
    })
    $('#results').on('click', e => {
      e.stopPropagation()
    })
    $('#term').on('click focus', e => {
      e.stopPropagation()
      if (!!$('#results a').length)
        $('#results').removeClass('d-none')
    })
  }
}
