import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        member_first_name: {
          required: true
        },
        member_last_name: {
          required: true
        },
        member_email: {
          required: true,
          email: true
        },
        member_password: {
          required: true
        }
      }
    })
  }
}
